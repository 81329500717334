import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  
  {
    label: t('NFTs'),
    icon: 'NftIcon',
    href:'/nftpools',
    items: [
      
      {
        label: t('NFT Pools'),
        href: '/nftpools',
      }
      ,
      {
        label: t('Apply'),
        href: '/applynft',
      }
      
    ],
  },
  
 
]

export default config
