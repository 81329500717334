import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
   {
    pid: 0,
    lpSymbol: 'SCARY',
    lpAddresses: {
      96: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      56: '0xA3818F83c48ebAe25D939DEd1476Cf9fc2803C50',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'SCARY-BNB LP',
    lpAddresses: {
      96: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      56: '0xD88EaB6606f201A259e7849c414c07b3eac232c4',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      96: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 3,
    lpSymbol: '$DRAGONS-BNB LP',
    lpAddresses: {
      96: '',
      56: '0x67646E4b8cEa33D3df85b1726FDC0Be609684c5D',
    },
    token: tokens.dragon,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 4,
    lpSymbol: 'SSM-BNB LP',
    lpAddresses: {
      96: '',
      56: '0x9D6f451F2821Cf4d13F14b63FC46E75DC7140937',
    },
    token: tokens.ssm,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'GUT-BNB LP',
    lpAddresses: {
      96: '',
      56: '0x3a42841F6e31D83F132743f5DfC1743DB3eD8bBf',
    },
    token: tokens.gut,
    quoteToken: tokens.wbnb,

  },
  
  /**
   * 
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
  
  
  
]

export default farms
