import { ChainId, Token } from '@pancakeswap/sdk'

export const SCARY: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xA3818F83c48ebAe25D939DEd1476Cf9fc2803C50',
    9,
    'THT',
    'Thunder Hoodie Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'SCARY',
    'PancakeSwap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}

export const WBNB = new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
)
// export const USDC = new Token(
//   ChainId.MAINNET,
//   '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
//   18,
//   'USDC',
//   'Binance-Peg USD Coin',
// )

const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },
  cake: {
    symbol: 'SCARY',
    address: {
      56: '0xA3818F83c48ebAe25D939DEd1476Cf9fc2803C50',
      96: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
 
  wbnb: {
    symbol: 'wBNB',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      96: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  eth: {
    symbol: 'ETH',
    address: {
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/',
  },
  dai: {
    symbol: 'DAI',
    address: {
      56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://www.makerdao.com/',
  },
  
  uni: {
    symbol: 'UNI',
    address: {
      56: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://www.makerdao.com/',
  },
  scy: {
    symbol: 'SCY',
    address: {
      56: '0x06d7645f4f483bb925db2094dD5fdb1f75B07D61',
      96: '',
    },
    decimals: 9,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },

  dragon: {
    symbol: '$DRAGONS',
    address: {
      56: '0x35D975d9b6BfeE81d1E060D6DDE07a5b082cB79B',
      96: '',
    },
    decimals: 9,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  newdragon: {
    symbol: 'BNBDRAGON',
    address: {
      56: '0xDE23183475f26a40802CcbE48394934261EA3736',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  newdragonNFT: {
    symbol: 'BNBDRAGON',
    address: {
      56: '0xE141d3364d2d1C358987855fd9c804D37b2E646d',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  newdragoncrane: {
    symbol: 'CRANE',
    address: {
      56: '0x8c85a0e86f37ca231463Ead62a40350D7Bf7cEf0',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  newdragoncraneNFT: {
    symbol: 'CM',
    address: {
      56: '0x8F441B42BD9D5a8149eEFF6fC1246B69d56A78A2',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  newdr1ver: {
    symbol: 'DR1$',
    address: {
      56: '0xf382d257A468f5B8b72d35e09ca0193d0C424401',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  newdriftdeliveryNFT: {
    symbol: 'DD',
    address: {
      56: '0xaec92d302CE4b7F175B33Ef16f64145B244D227c',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  
  scarymonsterNFT: {
    symbol: 'SCM',
    address: {
      56: '0x0f3E39fe2a198d01e6E6991694F5019dC38A1a89',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  gut: {
    symbol: 'GUT',
    address: {
      56: '0xb6bA8c98021C31A02DD65e9bE97729EbA859d4E2',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  ssm: {
    symbol: 'SSM',
    address: {
      56: '0x518c521b262204eb1EDa670771CfC1D3bb6A9c34',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  pancake: {
    symbol: 'Cake',
    address: {
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://swap.thunderhoodietoken.com/',
  },
  twt: {
    symbol: 'TWT',
    address: {
      56: '0x4b0f1812e5df2a09796481ff14017e6005508003',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://www.makerdao.com/',
  },
  link: {
    symbol: 'LINK',
    address: {
      56: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://www.makerdao.com/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      56: '0x55d398326f99059fF775485246999027B3197955',
      96: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  ust: {
    symbol: 'UST',
    address: {
      56: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
      96: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  btcb: {
    symbol: 'BTCB',
    address: {
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      96: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  ada: {
    symbol: 'ADA',
    address: {
      56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
      96: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  ltc: {
    symbol: 'LTC',
    address: {
      56: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
      96: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  dot: {
    symbol: 'DOT',
    address: {
      56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
      96: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  trx: {
    symbol: 'TRX',
    address: {
      56: '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
      96: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  syrup: {
    symbol: 'SCYRT',
    address: {
      56: '0x559471f0B0A5df3854bd7FdbFAb6F14eFA6Cc355',
      96: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  
}

export default tokens
