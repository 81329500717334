import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Thunder Hoodie Token',
  description:
    'The most popular AMM on BSC by user count! Earn SCARY through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Thunder Hoodie Token), NFTs, and more, on a platform you can trust.',
  image: 'https://swap.thunderhoodietoken.com/logo.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Thunder Hoodie Token')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Thunder Hoodie Token')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Thunder Hoodie Token')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Thunder Hoodie Token')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Thunder Hoodie Token')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Thunder Hoodie Token')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('Thunder Hoodie Token')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Thunder Hoodie Token')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Thunder Hoodie Token')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('Thunder Hoodie Token')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('Thunder Hoodie Token')}`,
      }
    default:
      return null
  }
}
