import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.cake,
    earningToken: tokens.cake,
    contractAddress: {
      96: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0x54e696bf38365D0F741f598Ab3F0Ff4f74D1B478',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.3',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 1,
    stakingToken: tokens.cake,
    earningToken: tokens.dragon,
    contractAddress: {
      96: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0xdbb8040b55d707a665dc6964b88ea660f8254b3d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.24',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 2,
    stakingToken: tokens.cake,
    earningToken: tokens.gut,
    contractAddress: {
      96: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0x1cda521b36c7ffdc54e39d0c302540359f8f7ef4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.28',
    sortOrder: 1,
    isFinished: false,

  },
  {
    sousId: 3,
    stakingToken: tokens.cake,
    earningToken: tokens.ssm,
    contractAddress: {
      96: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0x4ab6ce9808dd5cba783594fc71847d23aa31ef5b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.034',
    sortOrder: 1,
    isFinished: false,
   }
  // ,
  // {
  //   sousId: 4,
  //   stakingToken: tokens.cake,
  //   earningToken: tokens.pancake,
  //   contractAddress: {
  //     96: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x54e696bf38365D0F741f598Ab3F0Ff4f74D1B478',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '0.3',
  //   sortOrder: 1,
  //   isFinished: false,
  // },
]

export default pools
